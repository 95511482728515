import sensor_17_webp from "./webp/Hauswasserzahler.webp";
import sensor_17_v2_webp from "./webp/Hauswasserzahler@2x.webp";
import sensor_17_v3_webp from "./webp/Hauswasserzahler@3x.webp";
import sensor_18_webp from "./webp/Rukspulfilter.webp";
import sensor_18_v2_webp from "./webp/Rukspulfilter@2x.webp";
import sensor_18_v3_webp from "./webp/Rukspulfilter@3x.webp";
import sensor_19_webp from "./webp/Warmwasserspeicher1.webp";
import sensor_19_v2_webp from "./webp/Warmwasserspeicher1@2x.webp";
import sensor_19_v3_webp from "./webp/Warmwasserspeicher1@3x.webp";
import sensor_20_webp from "./webp/Warmwasserspeicher2.webp";
import sensor_20_v2_webp from "./webp/Warmwasserspeicher2@2x.webp";
import sensor_20_v3_webp from "./webp/Warmwasserspeicher2@3x.webp";

import sensor_22_webp from "./webp/Heizkreispumpe1.webp";
import sensor_22_v2_webp from "./webp/Heizkreispumpe1@2x.webp";
import sensor_22_v3_webp from "./webp/Heizkreispumpe1@3x.webp";
import sensor_23_webp from "./webp/Heizkreispumpe2.webp";
import sensor_23_v2_webp from "./webp/Heizkreispumpe2@2x.webp";
import sensor_23_v3_webp from "./webp/Heizkreispumpe2@3x.webp";
import sensor_24_webp from "./webp/Heizkreispumpe3.webp";
import sensor_24_v2_webp from "./webp/Heizkreispumpe3@2x.webp";
import sensor_24_v3_webp from "./webp/Heizkreispumpe3@3x.webp";
import sensor_25_webp from "./webp/Heizkreispumpe4.webp";
import sensor_25_v2_webp from "./webp/Heizkreispumpe4@2x.webp";
import sensor_25_v3_webp from "./webp/Heizkreispumpe4@3x.webp";
import sensor_26_webp from "./webp/Zirkulationspumpe1.webp";
import sensor_26_v2_webp from "./webp/Zirkulationspumpe1@2x.webp";
import sensor_26_v3_webp from "./webp/Zirkulationspumpe1@3x.webp";
import sensor_27_webp from "./webp/Zirkulationspumpe2.webp";
import sensor_27_v2_webp from "./webp/Zirkulationspumpe2@2x.webp";
import sensor_27_v3_webp from "./webp/Zirkulationspumpe2@3x.webp";
import sensor_28_webp from "./webp/Warmetauscher1.webp";
import sensor_28_v2_webp from "./webp/Warmetauscher1@2x.webp";
import sensor_28_v3_webp from "./webp/Warmetauscher1@3x.webp";
import sensor_28001_webp from "./webp/Warmetauscher2.webp";
import sensor_28001_v2_webp from "./webp/Warmetauscher2@2x.webp";
import sensor_28001_v3_webp from "./webp/Warmetauscher2@3x.webp";
import sensor_29_webp from "./webp/Vorlagepumpe.webp";
import sensor_29_v2_webp from "./webp/Vorlagepumpe@2x.webp";
import sensor_29_v3_webp from "./webp/Vorlagepumpe@3x.webp";
import sensor_30_webp from "./webp/Kesselpumpe1.webp";
import sensor_30_v2_webp from "./webp/Kesselpumpe1@2x.webp";
import sensor_30_v3_webp from "./webp/Kesselpumpe1@3x.webp";
import sensor_31_webp from "./webp/Kesselpumpe2.webp";
import sensor_31_v2_webp from "./webp/Kesselpumpe2@2x.webp";
import sensor_31_v3_webp from "./webp/Kesselpumpe2@3x.webp";
import sensor_32_webp from "./webp/Heizkessel1.webp";
import sensor_32_v2_webp from "./webp/Heizkessel1@2x.webp";
import sensor_32_v3_webp from "./webp/Heizkessel1@3x.webp";
import sensor_33_webp from "./webp/Heizkessel2.webp";
import sensor_33_v2_webp from "./webp/Heizkessel2@2x.webp";
import sensor_33_v3_webp from "./webp/Heizkessel2@3x.webp";
import sensor_34_webp from "./webp/Speicherladesystem1.webp";
import sensor_34_v2_webp from "./webp/Speicherladesystem1@2x.webp";
import sensor_34_v3_webp from "./webp/Speicherladesystem1@3x.webp";
import sensor_35_webp from "./webp/Speicherladesystem2.webp";
import sensor_35_v2_webp from "./webp/Speicherladesystem2@2x.webp";
import sensor_35_v3_webp from "./webp/Speicherladesystem2@3x.webp";

import sensor_17_jpg from "./jpg/Hauswasserzahler.jpg";
import sensor_17_v2_jpg from "./jpg/Hauswasserzahler@2x.jpg";
import sensor_17_v3_jpg from "./jpg/Hauswasserzahler@3x.jpg";
import sensor_18_jpg from "./jpg/Rukspulfilter.jpg";
import sensor_18_v2_jpg from "./jpg/Rukspulfilter@2x.jpg";
import sensor_18_v3_jpg from "./jpg/Rukspulfilter@3x.jpg";
import sensor_19_jpg from "./jpg/Warmwasserspeicher1.jpg";
import sensor_19_v2_jpg from "./jpg/Warmwasserspeicher1@2x.jpg";
import sensor_19_v3_jpg from "./jpg/Warmwasserspeicher1@3x.jpg";
import sensor_20_jpg from "./jpg/Warmwasserspeicher2.jpg";
import sensor_20_v2_jpg from "./jpg/Warmwasserspeicher2@2x.jpg";
import sensor_20_v3_jpg from "./jpg/Warmwasserspeicher2@3x.jpg";

import sensor_22_jpg from "./jpg/Heizkreispumpe1.jpg";
import sensor_22_v2_jpg from "./jpg/Heizkreispumpe1@2x.jpg";
import sensor_22_v3_jpg from "./jpg/Heizkreispumpe1@3x.jpg";
import sensor_23_jpg from "./jpg/Heizkreispumpe2.jpg";
import sensor_23_v2_jpg from "./jpg/Heizkreispumpe2@2x.jpg";
import sensor_23_v3_jpg from "./jpg/Heizkreispumpe2@3x.jpg";
import sensor_24_jpg from "./jpg/Heizkreispumpe3.jpg";
import sensor_24_v2_jpg from "./jpg/Heizkreispumpe3@2x.jpg";
import sensor_24_v3_jpg from "./jpg/Heizkreispumpe3@3x.jpg";
import sensor_25_jpg from "./jpg/Heizkreispumpe4.jpg";
import sensor_25_v2_jpg from "./jpg/Heizkreispumpe4@2x.jpg";
import sensor_25_v3_jpg from "./jpg/Heizkreispumpe4@3x.jpg";
import sensor_26_jpg from "./jpg/Zirkulationspumpe1.jpg";
import sensor_26_v2_jpg from "./jpg/Zirkulationspumpe1@2x.jpg";
import sensor_26_v3_jpg from "./jpg/Zirkulationspumpe1@3x.jpg";
import sensor_27_jpg from "./jpg/Zirkulationspumpe2.jpg";
import sensor_27_v2_jpg from "./jpg/Zirkulationspumpe2@2x.jpg";
import sensor_27_v3_jpg from "./jpg/Zirkulationspumpe2@3x.jpg";
import sensor_28_jpg from "./jpg/Warmetauscher1.jpg";
import sensor_28_v2_jpg from "./jpg/Warmetauscher1@2x.jpg";
import sensor_28_v3_jpg from "./jpg/Warmetauscher1@3x.jpg";
import sensor_28001_jpg from "./jpg/Warmetauscher2.jpg";
import sensor_28001_v2_jpg from "./jpg/Warmetauscher2@2x.jpg";
import sensor_28001_v3_jpg from "./jpg/Warmetauscher2@3x.jpg";
import sensor_29_jpg from "./jpg/Vorlagepumpe.jpg";
import sensor_29_v2_jpg from "./jpg/Vorlagepumpe@2x.jpg";
import sensor_29_v3_jpg from "./jpg/Vorlagepumpe@3x.jpg";
import sensor_30_jpg from "./jpg/Kesselpumpe1.jpg";
import sensor_30_v2_jpg from "./jpg/Kesselpumpe1@2x.jpg";
import sensor_30_v3_jpg from "./jpg/Kesselpumpe1@3x.jpg";
import sensor_31_jpg from "./jpg/Kesselpumpe2.jpg";
import sensor_31_v2_jpg from "./jpg/Kesselpumpe2@2x.jpg";
import sensor_31_v3_jpg from "./jpg/Kesselpumpe2@3x.jpg";
import sensor_32_jpg from "./jpg/Heizkessel1.jpg";
import sensor_32_v2_jpg from "./jpg/Heizkessel1@2x.jpg";
import sensor_32_v3_jpg from "./jpg/Heizkessel1@3x.jpg";
import sensor_33_jpg from "./jpg/Heizkessel2.jpg";
import sensor_33_v2_jpg from "./jpg/Heizkessel2@2x.jpg";
import sensor_33_v3_jpg from "./jpg/Heizkessel2@3x.jpg";
import sensor_34_jpg from "./jpg/Speicherladesystem1.jpg";
import sensor_34_v2_jpg from "./jpg/Speicherladesystem1@2x.jpg";
import sensor_34_v3_jpg from "./jpg/Speicherladesystem1@3x.jpg";
import sensor_35_jpg from "./jpg/Speicherladesystem2.jpg";
import sensor_35_v2_jpg from "./jpg/Speicherladesystem2@2x.jpg";
import sensor_35_v3_jpg from "./jpg/Speicherladesystem2@3x.jpg";

const sNiehlerGurtel = {
    webp: {
        s_17: [sensor_17_webp, sensor_17_v2_webp, sensor_17_v3_webp],
        s_18: [sensor_18_webp, sensor_18_v2_webp, sensor_18_v3_webp],
        s_19: [sensor_19_webp, sensor_19_v2_webp, sensor_19_v3_webp],
        s_20: [sensor_20_webp, sensor_20_v2_webp, sensor_20_v3_webp],
        s_22: [sensor_22_webp, sensor_22_v2_webp, sensor_22_v3_webp],
        s_23: [sensor_23_webp, sensor_23_v2_webp, sensor_23_v3_webp],
        s_24: [sensor_24_webp, sensor_24_v2_webp, sensor_24_v3_webp],
        s_25: [sensor_25_webp, sensor_25_v2_webp, sensor_25_v3_webp],
        s_26: [sensor_26_webp, sensor_26_v2_webp, sensor_26_v3_webp],
        s_27: [sensor_27_webp, sensor_27_v2_webp, sensor_27_v3_webp],
        s_28: [sensor_28_webp, sensor_28_v2_webp, sensor_28_v3_webp],
        s_28001: [sensor_28001_webp, sensor_28001_v2_webp, sensor_28001_v3_webp],
        s_29: [sensor_29_webp, sensor_29_v2_webp, sensor_29_v3_webp],
        s_30: [sensor_30_webp, sensor_30_v2_webp, sensor_30_v3_webp],
        s_31: [sensor_31_webp, sensor_31_v2_webp, sensor_31_v3_webp],
        s_32: [sensor_32_webp, sensor_32_v2_webp, sensor_32_v3_webp],
        s_33: [sensor_33_webp, sensor_33_v2_webp, sensor_33_v3_webp],
        s_34: [sensor_34_webp, sensor_34_v2_webp, sensor_34_v3_webp],
        s_35: [sensor_35_webp, sensor_35_v2_webp, sensor_35_v3_webp]
    },
    png: {
        s_17: [sensor_17_jpg, sensor_17_v2_jpg, sensor_17_v3_jpg],
        s_18: [sensor_18_jpg, sensor_18_v2_jpg, sensor_18_v3_jpg],
        s_19: [sensor_19_jpg, sensor_19_v2_jpg, sensor_19_v3_jpg],
        s_20: [sensor_20_jpg, sensor_20_v2_jpg, sensor_20_v3_jpg],
        s_22: [sensor_22_jpg, sensor_22_v2_jpg, sensor_22_v3_jpg],
        s_23: [sensor_23_jpg, sensor_23_v2_jpg, sensor_23_v3_jpg],
        s_24: [sensor_24_jpg, sensor_24_v2_jpg, sensor_24_v3_jpg],
        s_25: [sensor_25_jpg, sensor_25_v2_jpg, sensor_25_v3_jpg],
        s_26: [sensor_26_jpg, sensor_26_v2_jpg, sensor_26_v3_jpg],
        s_27: [sensor_27_jpg, sensor_27_v2_jpg, sensor_27_v3_jpg],
        s_28: [sensor_28_jpg, sensor_28_v2_jpg, sensor_28_v3_jpg],
        s_28001: [sensor_28001_jpg, sensor_28001_v2_jpg, sensor_28001_v3_jpg],
        s_29: [sensor_29_jpg, sensor_29_v2_jpg, sensor_29_v3_jpg],
        s_30: [sensor_30_jpg, sensor_30_v2_jpg, sensor_30_v3_jpg],
        s_31: [sensor_31_jpg, sensor_31_v2_jpg, sensor_31_v3_jpg],
        s_32: [sensor_32_jpg, sensor_32_v2_jpg, sensor_32_v3_jpg],
        s_33: [sensor_33_jpg, sensor_33_v2_jpg, sensor_33_v3_jpg],
        s_34: [sensor_34_jpg, sensor_34_v2_jpg, sensor_34_v3_jpg],
        s_35: [sensor_35_jpg, sensor_35_v2_jpg, sensor_35_v3_jpg]
    }
}

export { sNiehlerGurtel };
